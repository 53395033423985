var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mew-module", {
    staticClass: "mew-component--convert-units pt-6",
    attrs: {
      title: "Generate Keystore file",
      "has-elevation": true,
      "has-indicator": true,
    },
    scopedSlots: _vm._u([
      {
        key: "moduleBody",
        fn: function () {
          return [
            _c("mew-stepper", {
              attrs: {
                compact: _vm.$vuetify.breakpoint.smAndDown,
                items: _vm.stepperItems,
                "on-step": _vm.onStep,
              },
              on: { onContinue: _vm.nextStep },
              scopedSlots: _vm._u(
                [
                  _vm.onStep === 1
                    ? {
                        key: "stepperContent1",
                        fn: function () {
                          return [
                            _c("step-one", {
                              attrs: { skipped: _vm.skipped },
                              on: { onContinue: _vm.nextStep, back: _vm.back },
                            }),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                  _vm.onStep === 2
                    ? {
                        key: "stepperContent2",
                        fn: function () {
                          return [
                            _c("step-two", {
                              attrs: {
                                skipped: _vm.skipped,
                                address: _vm.address,
                              },
                              on: { onContinue: _vm.nextStep, back: _vm.back },
                            }),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }